.NewHome__head {
  min-height: 100vh;
}
.NewHome__aboutUs {
  padding: 40px 30px;
  min-height: calc(100vh - 80px);
  background-size: cover;
  background-image: url('https://res.cloudinary.com/pataformacgi/image/upload/c_scale,h_700/v1674760868/assets/FONDO_BLANCO_jhhzrw.png');
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 35px;
}
.NewHome__content {
  min-height: calc(100vh - 80px);
  display: flex;
  justify-content: space-between;
}
.CourseSection {
  width: 50%;
  height: 100vh;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.CourseSection__icon {
  font-size: 8rem;
  color: var(--white);
  background-color: #1999a7;
  border-radius: 50%;
}
.CourseSection__text {
  font-size: 3.5rem;
  font-weight: 900;
  text-align: center;
}
.CourseSection__text span {
  color: var(--orange2);
}
.NewHome__AText p {
  font-size: 1.9rem;
}
.NewHome__AImage {
  width: 600px;
}
.NewHome__listSection {
  padding: 30px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  gap: 35px;
}
.CardSection {
  background: #eeeeee;
  padding: 10px 15px 10px 15px;
  border-radius: 5px;
  width: 350px;
}
.CardSection__head {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  border-radius: 15px;
  min-width: 320px;
  margin-bottom: 10px;
}
.CardSection .CardSection__text {
  font-size: 1.6rem;
  text-align: center;
}
.NewHome__contact p {
  font-size: 1.6rem;
}
.NewHome__contactContain {
  padding: 40px 30px;
  min-height: 100vh;
  background-size: cover;
  background-image: url('https://res.cloudinary.com/pataformacgi/image/upload/c_scale,h_700/v1674760868/assets/FONDO_BLANCO_jhhzrw.png');
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 35px;
}
.NewHome__contactMap {
  min-width: 50%;
}
@media screen and (max-width: 1200px) {
  .NewHome__AImage {
    width: 50%;
  }
}

@media screen and (max-width: 900px) {
  .NewHome__aboutUs {
    flex-direction: column;
    gap: 10px;
  }
}
@media screen and (max-width: 700px) {
  .NewHome__head {
    min-height: auto;
  }
  .EducationVirtual {
    margin-top: 0;
  }
  .EducationVirtual h2 {
    text-align: center;
  }
  .NewHome__AText p {
    font-size: 1.6rem;
  }
  .NewHome__contactContain {
    flex-direction: column-reverse;
  }
  .NewHome__contactMap {
    min-width: auto;
    width: 100%;
  }
  .NewHome__content {
    flex-direction: column;
  }
  .CourseSection {
    min-height: auto;
    width: 100%;
  }

  .NewHome__AImage {
    width: 350px;
  }
}
@media screen and (max-width: 425px) {
  .CardSection {
    width: 100%;
  }
  .CardSection__head {
    min-width: auto;
  }
  .NewHome__listSection {
    padding: 30px 10px;
    gap: 15px;
  }
  .NewHome__AImage {
    width: 90%;
  }
}
