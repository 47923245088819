.containerLog {
  display: flex;
  height: 100%;
  font-size: 1.6rem;
  background-image: url('../../../assets/img/bgLogin.png');
  background-repeat: no-repeat;
  background-size: contain;
  justify-content: center;
}
.containerLogin__img {
  display: flex;
  width: 61%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #6a3081b3;
}
.containerLogin__img img {
  margin-top: 20px;

  width: 190px;
}
.containerLogin__ul {
  color: var(--white);
  list-style: none;
}
.containerLogin__ul li {
  margin-bottom: 10px;
}
.containerLogin__form {
  width: 100%;
  padding: 40px 0;
}

@media screen and (max-width: 900px) {
  .containerLog {
    background-image: none;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .containerLogin__form {
    justify-content: center;
    padding-top: 20px;
  }
  .containerLogin__img {
    display: none;
  }
}
