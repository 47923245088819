@import url("https://fonts.googleapis.com/css2?family=Spartan:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
body {
  font-family: "Spartan", sans-serif;
  margin: 0;
}
button {
  font-family: "Spartan", sans-serif;
  border: none;
}

/* font-family: 'Roboto', sans-serif; */
