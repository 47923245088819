.ButtonModalS {
  color: var(--purple);
  font-size: 2.5rem;
}
.ButtonModalS__login {
  font-size: 2.5rem;
  color: var(--purple);
  border: 1px solid var(--purple);
  border-radius: 20px;
  padding: 5px 5px 5px 10px;
  display: flex;
  gap: 5px;
  align-items: center;
  text-decoration: none;
}
.ButtonModalS__login span {
  font-size: 1.8rem;
  font-weight: 500;
  padding-top: 2px;
}
.userPhoto__container {
  display: flex;
  align-items: center;
  gap: 5px;
  border: 1px solid var(--purple);
  border-radius: 20px;
  padding: 2px 8px 2px 2px;
  cursor: pointer;
}
.userPhoto__img {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 1px solid var(--purple);
}
.userPhoto .userPhoto__ul {
  position: absolute;
  right: 20px;
  list-style: none;
  z-index: 1;
  padding: 10px;
  border-radius: 5px;
  background: var(--white);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.16);
  margin-top: 5px;
  font-size: 1.6rem;
}
.userPhoto__link {
  color: var(--black);
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  padding: 8px 0;
}
.userPhoto__li:hover,
.userPhoto__li .userPhoto__link:hover {
  cursor: pointer;
  color: var(--purple);
}
.Notification {
  position: relative;
}
.Notification__icon {
  position: relative;
  cursor: pointer;
}
.Notification__icon .H3 {
  border-radius: 20px;
  padding: 3px 6px;
  background: #f02929;
  position: absolute;
  left: 10px;
  top: -5px;
  border: 2px solid var(--white);
}
.Notification__container {
  width: 250px;
  position: absolute;
  border-radius: 10px;
  background: #f7f7f7;
  top: 35px;
  right: 50%;
  margin-right: -100px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.16);
  z-index: 2;
}
.Notification__closeModal {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.2);
  width: 100vw;
  height: 100vh;
  z-index: 1;
}
.Notification__main {
  background: var(--white);
  border-radius: 0 0 10px 10px;
  display: flex;
  flex-direction: column;
}
.Notification__notify {
  text-decoration: none;
  background: #458e7f31;
}
.Notification__notify:last-child {
  border-radius: 0 0 10px 10px;
}
.Notification__notify:hover {
  opacity: 0.7;
}
@media screen and (max-width: 600px) {
  .userPhoto__container {
    border: none;
  }
  .userPhoto__container .H3 {
    display: none;
  }
}
