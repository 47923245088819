.ContentQuiz {
  border-radius: 10px;
  padding: 10px 10px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  text-decoration: none;
  cursor: pointer;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}
.ContentQuiz.available {
  background: var(--white);
}
.ContentQuiz.scored,
.ContentQuiz.completed {
  background: #469585a1;
  color: var(--white);
}
.ContentQuiz:hover {
  box-shadow: 0px 0px 10px rgba(70, 149, 133, 0.5);
  transition: border 0.3s;
}
.ContentQuizRol {
  text-decoration: none;
  color: var(--black);
}
.ContentQuiz h2 {
  margin: 0;
  font-size: 1.8rem;
  margin: 10px 0 0;
}
.ContentQuiz h3 {
  color: rgba(0, 0, 0, 0.8);
  text-align: end;
  font-size: 1.6rem;
}
.ContentQuiz__act {
  position: relative;
}
.ContentQuiz__block {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}
.ContentQuiz__block h3 {
  color: var(--white);
  font-size: 1.6rem;
}
.ContentQuiz__block i {
  font-size: 2.5rem;
  color: var(--white);
  padding: 15px;
  border-radius: 50%;
}
.ContentQuiz__modal {
  background: var(--white);
  padding: 10px 20px;
  border-radius: 10px;
  text-align: start;
}
.ContentQuiz__modal h2 {
  font-size: 2rem;
}
.ContentQuiz__modal ul {
  margin: 15px 0;
}
.ContentQuiz__modal ul li {
  font-size: 1.6rem;
  margin: 8px 0;
}
.ContentQuiz__btns {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
}
.ContentQuizRol {
  margin: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.ContentQuizRol h2 {
  margin: 0 0 5px;
}
