.Catalogue__title .H2 {
  text-transform: uppercase;
  color: gray;
}
.Catalogue__head {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.Catalogue__video {
  width: 50%;
}
.Catalogue__navigation {
  gap: 10px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}
.Catalogue__head .NavCatalogue a {
  display: block;
  width: fit-content;
}
.Catalogue__head .NavCatalogue h1 {
  display: block;
  font-weight: 600;
  font-size: 2rem;
}
@media screen and (max-width: 768px) {
  .Catalogue__video {
    width: 100%;
  }
  .Catalogue__head {
    flex-direction: column;
  }
}
